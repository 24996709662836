import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/recover',
    name: 'Recover',
    component: () => import('../views/Recover.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/responses',
    name: 'Responses',
    component: () => import('../views/Responses.vue')
  },
  {
    path: '/userhome',
    name: 'UserHome',
    component: () => import('../views/UserHome.vue')
  },
  {
    path: '/compile/:projectId',
    name: 'Compile',
    component: () => import('../views/Compile.vue')
  },
  {
    path: '/compile/:userId/:projectId',
    name: 'CompileAdmin',
    component: () => import('../views/Compile.vue')
  },
  {
    path: '/questionnaire/:id',
    name: 'Questionnaire',
    component: () => import('../views/Questionnaire.vue')
  },
  {
    path: '/chooseproject',
    name: 'ChooseProject',
    component: () => import('../views/ChooseProject.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
