<template>
  <b-card
    title="S-LCA"
    class="col-4 offset-md-4">
    <b-form
      @submit="onSubmit">
      <b-form-input v-model="email" placeholder="Email" class="mb-3"></b-form-input>
      <b-form-input v-model="password" type="password" placeholder="Password" class="mb-3"></b-form-input>

      <b-button type="submit" variant="success">Login</b-button>

      <br><br>

      <router-link to="/recover">Forgotten password</router-link>
    </b-form>

    <b-alert :show="error" variant="danger" class="mt-3">Check your credentials</b-alert>

    <center>
      <img src="@/assets/pluslife.png" style="width: 250px; margin-top: 40px;" />
    </center>
  </b-card>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      email: '',
      password: '',
      error: false
    };
  },
  async mounted () {
    if (this.$store.state.isLogged) {
      this.pushAway(this.$store.state.user.role)
    }
  },
  methods: {
    pushAway (role) {
      if (role == 'USER') {
        this.$router.push('/userhome')
      } else {
        this.$router.push('/dashboard')
      }
    },

    async getProjects (company) {
        let resp = await this.$firestore.collection('projects').get()
        let projectsResponse = this.getSnapshotRecords(resp, false)
        let projects = []

        projectsResponse.forEach((item) => {
          if (item.companies) {
            let comps = item.companies.split(',')
            if (comps.includes(company)) {
              projects.push({
                id: item.id,
                name: item.name
              })
            }
          }
        })

        return projects
    },

    async onSubmit (evt) {
      evt.preventDefault();
      this.error = false 

      try {
        const resp = await this.$auth.signInWithEmailAndPassword(this.email, this.password)
        const tokenData = await this.$auth.currentUser.getIdTokenResult()
        const projects = await this.getProjects(tokenData.claims.company)

        this.$store.commit('login', {
          id: resp.user.uid,
          role: tokenData.claims.role,
          email: resp.user.email,
          name: resp.user.displayName,
          projects //tokenData.claims.projects
        })

        this.pushAway(tokenData.claims.role)
      } catch (e) {
        console.log(e)
        this.error = 5
      }
    }
  }
}
</script>
