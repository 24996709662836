import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const loggedOutUser = {
  name: '',
  email: '',
  role: '',
  uid: ''
}

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    isLogged: false,
    user: loggedOutUser
  },
  mutations: {
    login (state, userData) {
      state.isLogged = true
      state.user = {
        email: userData.email,
        name: userData.name,
        role: userData.role,
        uid: userData.id,
        projects: userData.projects
      }
    },
    logout (state) {
      state.isLogged = false
      state.user = loggedOutUser
    }
  },
  actions: {
  },
  modules: {
  },
})
