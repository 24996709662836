import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { format } from 'date-fns'

const firebaseConfig = {
  apiKey: "AIzaSyCfM5lojv5WsIOrLkrOxbLar3oV46TD6FE",
  authDomain: "lce-slca.firebaseapp.com",
  projectId: "lce-slca",
  storageBucket: "lce-slca.appspot.com",
  messagingSenderId: "68913094394",
  appId: "1:68913094394:web:5df6141f7cc28509887343"
}

let FirebasePlugin = {}

FirebasePlugin.install = function (Vue) {
  let fbInstall = firebase.initializeApp(firebaseConfig)

  Vue.prototype.$firebase = firebase
  Vue.prototype.$firestore = fbInstall.firestore()
  Vue.prototype.$auth = fbInstall.auth()
  Vue.prototype.Timestamp = fbInstall.firestore.Timestamp

  Vue.prototype.getSnapshotRecords = function (snapshot, single) {
    let res = [];

    snapshot.forEach((doc) => {
      let docData = doc.data()

      for (let index in docData) {
        if (docData[index].toDate) {
          docData[index] = format(docData[index].toDate(), 'yyyy-MM-dd')
        }
      }
      res.push({
        ...docData,
        id: doc.id
      })
    })

    return single ? res[0] : res;
  }
}

export default FirebasePlugin
