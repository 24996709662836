<template>
  <div id="app">
    <b-navbar variant="light">
      <b-navbar-brand to="/">
        <img src="@/assets/logo.png" class="mr-4" />S-LCA DATA COLLECTION
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto" v-if="$store.state.isLogged">
        <b-nav-item to="/dashboard" v-if="$store.state.user.role == 'ADMIN'">Projects</b-nav-item>
        <b-nav-item to="/users" v-if="$store.state.user.role == 'ADMIN'">Users</b-nav-item>
        <b-nav-item to="/responses" v-if="$store.state.user.role == 'ADMIN'">Questionnaires</b-nav-item>
        <b-nav-item href="/logout" @click="logout">Logout</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <div class="container my-5">
      <div class="row">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout (evt) {
      evt.preventDefault()
      this.$store.commit('logout')
      this.$router.push('/')
    }
  }
}
</script>

<style>
#app {
 font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.navbar-light.bg-light {
  -webkit-box-shadow: 0px 5px 5px 0px #cccccc;
  box-shadow: 0px 5px 5px 0px #cccccc;
  background-color: #fff !important;
}

.navbar-brand img {
  width: 50px;
}
</style>
