import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.css'
import FirebasePlugin from './firebase'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VTooltip from 'v-tooltip'


const axiosConfig = {
  //baseURL: 'http://localhost:5001/lce-slca/us-central1/api',
  baseURL: 'https://us-central1-lce-slca.cloudfunctions.net/api',
  timeout: 30000
};

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(FirebasePlugin)
Vue.use(VueSweetalert2)
Vue.use(VTooltip)
Vue.prototype.$axios = axios.create(axiosConfig)
Vue.prototype.$params = axiosConfig

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
